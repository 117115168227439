.cont-SectionPosts {
  width: 100%;
  background-color: #1c3740;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0;
  position: relative;
}

.cont-SectionPosts h2{
  color: #fff;
  letter-spacing: 2px;
}
