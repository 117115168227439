.navBar {
  display: flex;
  justify-content: space-evenly;
  background-color: #151e21;
  padding: 1rem 0;
}

.cont-user-sesion {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cont-user-sesion img {
  border-radius: 50%;
  box-shadow: 1px 1px 3px black;
  max-width: 50px;
}

.cont-user-sesion h4 {
  color: #7ddfee;
  font-size: 0.8rem;
  padding-left: 1rem;
}

.custom-btn-logout {
  background-color: tomato;
  padding: 0.8rem 2.5rem;
  border-radius: 10px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 1px;
  transition: all 0.3s;
  font-size: 0.8rem;
  border: none;
}

.custom-btn-logout:hover {
  transform: scale(1.05);
  filter: brightness(1.1);
  cursor: pointer;
}

@media (max-width: 425px) {
  .custom-btn-logout {
    padding: 0.2rem 1rem;
    font-size: 0.6rem;
  }
}
