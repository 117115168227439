.cont-bg-modal-commets{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.502);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.cont-modal-comments {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1C3740;
  border-radius: 15px;
  border: 2px solid #2CC4EE;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 300px;
  min-height: 300px;
  z-index: 999;
  color: #fff;
}

.cont-modal-comments h3{
  padding: 3rem 0 1.5rem 0;
}

.btn-close-modal{
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: tomato;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 7px;
  transition: all 0.3s;
}

.btn-close-modal:hover{
  transform: scale(1.05);
  cursor: pointer;
}